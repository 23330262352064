<template>
  <div>
    <el-collapse style="width:97%;margin: 0 auto" v-model="activeImage">
      <el-collapse-item v-for="(item,index) in styleImageList" :key="item.id" :title="item.storeImageTypeName" :name="index">
        <el-col :span="12"  style="margin: 1% 0" v-for="(itemChild,indexChild) in item.image" :key="indexChild">
          <div>
            <el-image
                style="width: 270px; height: 320px;" @click="openImage(itemChild.styleImage)"
                :src="`https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/${itemChild.styleImage}?imageMogr2/rquality/60`" fit="contain"/>
          </div>
          <el-row style="text-align: center;width: 250px;cursor:pointer;">
              {{ itemChild.storeImageTypeName }}
          </el-row>
        </el-col>
      </el-collapse-item>
    </el-collapse>
    <!--    <el-row :gutter="50" style="overflow-y: auto;height: 650px;">-->

    <!--    </el-row>-->
    <el-drawer
        title="上传图片"
        :visible.sync="drawer"
        :append-to-body="true">
      <el-form label-width="100px" :model="form" :rules="rules" ref="form">
        <el-row>
          <el-col :span="15">
            <el-form-item label="图片类型" prop="storeImageTypeId">
              <el-select v-model="form.storeImageTypeId" placeholder="图片类型" clearable>
                <el-option
                    v-for="item in imageTypeIdArray"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-button type="primary" icon="el-icon-upload2" @click="addImage" :loading="loading">上 传</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-drawer>
    <!--  </el-dialog>-->
  </div>
</template>

<script>
export default {
  name: "style-store-image",
  data() {
    return {
      form: {
        storeImageTypeId: "",
        styleImage: "",
        storeStyleId: this.styleInfo.id,
        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      styleImageList: [],
      imageTypeIdArray: [],
      activeImage:[],

      rules: {
        storeImageTypeId: [
          {required: true, message: '请选择图片类型', trigger: 'change'},
        ],
      },
      imageUrl: "",
      file: {},
      flag: false,
      dialogState: this.state,
      loading: false,
      drawer: false
    }
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    styleInfo: {
      type: Object,
      required: true,
    }
  },
  created() {
    this.queryStyleImage();
    this.queryImageTypeIdsArr();
  },
  methods: {
    //查询款式图片
    queryStyleImage() {
      this.$axios({
        method: "GET",
        url: "/storeStyleImage/queryByStoreStyleId",
        params: {
          storeStyleId: this.styleInfo.id
        }
      }).then(response => {
        this.styleImageList = []
        this.styleImageList.push(...this.arrGroupBy(response.data.data));
        for(let index in this.styleImageList){
          this.activeImage.push(Number(index))
        }
      })
    },
    //放大图片
    openImage(link) {
      this.$viewerApi({
        images: ['https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/' + link],
      })
    },
    //查询款式类型
    queryImageTypeIdsArr() {
      this.$selectUtils.queryStoreStyleImageTypeIds().then(response => {
        this.imageTypeIdArray = JSON.parse(response.data.data);
      })
    },
    //图片根据类型分组
    arrGroupBy(array){
      if (array===undefined||array===null||array===[]||array.length===0) return [];
      //获取去重后的图片类型
      let typeName=[];
      array.map(k=>{return k.storeImageTypeName}).forEach(k=>{
        if (!typeName.includes(k)){
          typeName.push(k)
        }
      })

      let newArr=[];
      typeName.forEach(k=>{
        let arrItem={storeImageTypeName:k,image:[]}
        array.forEach(v=>{
          if(k===v.storeImageTypeName){
            arrItem.image.push(v)
          }
        })
        newArr.push(arrItem)
      })
      return newArr;
    },
    close: function () {
      this.$emit("close", false);
    },
    handleAvatarChangeIcon(file) {
      this.file = file.raw
      this.imageUrl =  URL.createObjectURL(file.raw)
    },
  }
}
</script>

<style scoped>
/deep/ .el-dialog {
  margin-top: 5% !important;
}

</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 280px;
  height: 410px;
  display: block;
}
</style>