<template>
  <el-dialog
      title="款式信息"
      width="60%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-row>
      <el-col :span="9">
        <libStyleImage v-if="true" :styleInfo="styleInfo" :state="true" @close="close"/>
      </el-col>
      <el-col :span="11">
        <el-form label-width="130px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="款式类型" prop="styleType">
                <el-input readonly v-model="form.styleType" class="searchParam"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="款式品牌">
                <el-input readonly v-model="form.brandName" class="searchParam"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="销售价格">
                <el-input readonly v-model="form.salePrice" type="number" class="searchParam"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="品牌款式系列">
                <el-select disabled v-model="form.storeSeriesId" filterable placeholder="选择系列" clearable @change="seriesChange">
                  <el-option
                      v-for="item in seriesIdArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item  label="品牌款式系列编号" prop="factoryName">
                <el-select disabled v-model="form.storeSeriesNumberId" filterable placeholder="选择系列编号" clearable>
                  <el-option
                      v-for="item in seriesNumIdArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item  label="款式标签" class="labelClass">
                <el-select disabled v-model="form.storeLabelIds" filterable multiple placeholder="选择标签" clearable>
                  <el-option
                      v-for="item in labelNameArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="款式介绍" prop="styleInfo">
                <el-input readonly type="textarea" v-model="form.styleInfo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="适合身形" prop="styleFit">
                <el-input readonly type="textarea" v-model="form.styleFit"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="不适合身形" prop="styleNoFit">
                <el-input readonly type="textarea" v-model="form.styleNoFit"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import libStyleImage from "@/pages/share/library/lib-style-image";
export default {
  name: "style-store-details.vue",
  data() {
    return {
      form:{
        styleType:this.styleInfo.storeTypeName,
        salePrice:this.styleInfo.salePrice,
        publishStatus:this.styleInfo.publishStatus,
        storeLabelIds:[],
        storeSeriesId:this.styleInfo.storeSeriesId,
        storeSeriesNumberId:this.styleInfo.storeSeriesNumberId,
        brandName:this.styleInfo.storeBrandName,
        styleInfo:this.styleInfo.styleInfo,
        styleFit:this.styleInfo.styleFit,
        styleNoFit:this.styleInfo.styleNoFit,
      },
      brandNameArray:[],
      labelNameArray:[],
      seriesIdArray:[],
      seriesNumIdArray:[],
      dialogState: this.state,

      tenantCrop:localStorage.getItem("tenantCrop"),
    }
  },
  components:{
    libStyleImage
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    styleInfo: {
      type: Object,
      required: true,
    }
  },
  watch:{
  },
  created() {
    this.queryLabelNames();
    this.queryStoreStyleSeries();
    this.queryStoreStyleSeriesNumber(this.styleInfo.storeSeriesId);
  },
  methods: {

    //查询标签类型
    queryLabelNames(){
      this.$selectUtils.queryStoreLabelIds().then(response=>{
        this.labelNameArray=JSON.parse(response.data.data);
        this.styleInfo.labelIds.split(",").forEach(k=>{
          this.form.storeLabelIds.push(Number(k));
        })


      })
    },
    //查询款式系列
    queryStoreStyleSeries(){
      this.$selectUtils.queryStoreSeriesIds(this.styleInfo.storeBrandId).then(response=>{
        this.seriesIdArray=JSON.parse(response.data.data);
      })
    },
    seriesChange(val){
      this.form.storeSeriesNumberId="";
      this.queryStoreStyleSeriesNumber(val);
    },
    //查询款式系列编号
    queryStoreStyleSeriesNumber(storeSeriesId){
      this.$selectUtils.queryStoreSeriesNumIds(this.styleInfo.storeBrandId,storeSeriesId).then(response=>{

        this.seriesNumIdArray=JSON.parse(response.data.data);
      })
    },
    close: function () {
      this.$emit("close", false);
    }
  }
}
</script>

<style scoped>
.searchParam{
  width: 150px;
}
.labelClass  .el-select{
  display:block;
}
</style>