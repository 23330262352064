<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-select v-model="form.storeTypeId" filterable placeholder="款式类型" clearable class="searchParam" size="medium">
            <el-option
                v-for="item in storeStyleTypeIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="form.storeLabelIds" filterable multiple placeholder="款式标签" clearable class="searchParam" size="medium">
            <el-option
                v-for="item in storeLabelIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search" size="medium">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <div class="infinite-list-wrapper">
      <el-row :gutter="20" style="margin: 0 auto;" class="list" v-infinite-scroll="load" :infinite-scroll-disabled="disabled">
        <el-col :span="4" v-for="item in styleList" :key="item.id" style="margin: 1% 0 0 0" class="list-item">
          <div style="width: 14vw;height: 19vw">
            <el-image
                style="width: 100%; height: 100%;cursor:zoom-in;" @click="openStyleImages(item.id)"
                :src="'https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/'+item.mainImage+'?imageMogr2/rquality/60'" fit="contain"/>
          </div>
          <div style="width: 14vw;">
            <el-row>
              <el-col :span="24" style="text-align: center">
                <el-dropdown trigger="click" @command="handleCommand">
                  <span class="el-dropdown-link" style="cursor:pointer;font-size: 13px;font-weight: bold;">
                    操作 <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-edit" :command="['edit',item]">款式信息</el-dropdown-item>
                    <!--                                      <el-dropdown-item icon="el-icon-picture-outline" :command="['styleImage',item]">款式图片</el-dropdown-item>-->
                    <!--                    <el-dropdown-item icon="el-icon-price-tag" :command="['stylePrice',item]">款式价格</el-dropdown-item>-->
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12" >
                <p class="pTitle" style="text-align: left;font-weight: bold;font-size: 13px">{{item.storeTypeName}}</p>
              </el-col>
              <el-col :span="12">
                <p class="pTitle" style="text-align: right;font-weight: bold;font-size: 13px">{{item.storeBrandName===''?'无品牌':item.storeBrandName}}</p>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12" >
                <p class="pTitle" style="text-align: left;font-weight: bold;font-size: 13px">{{item.storeSeriesName ===''?'无系列':item.storeSeriesName}}</p>
              </el-col>
              <el-col :span="12" >
                <p class="pTitle" style="text-align: right;font-weight: bold;font-size: 13px">{{item.storeSeriesNumberName ===''?'无系列编号':item.storeSeriesNumberName}}</p>
              </el-col>
            </el-row>

          </div>
        </el-col>
      </el-row>
      <p  v-loading="loading" element-loading-text="加载中"></p>
      <p v-if="noMore">没有更多了...</p>
    </div>
    <libStyleDetails v-if="dialog.openEditState" :styleInfo="dialog.styleInfo" :state="dialog.openEditState" @close="close"/>
  </div>
</template>

<script>
import libStyleDetails from "@/pages/share/library/lib-style-details";

export default {
  name: "lib-style-list",
  data(){
    return{
      form:{
        storeTypeId:"",
        storeLabelIds:"",
        publishStatus:"",
      },
      storeStyleTypeIdArray:[],
      storeLabelIdArray:[],
      dialog:{
        openEditState:false,
        openImageState:false,
        openStylePrice:false,
        styleInfo:{},
      },

      page:1,
      styleList:[],
      total:0,
      loading: true,
      disabled:true,
      noMore:false,
    }
  },
  created() {
    this.queryStyleList();
    this.queryStoreStyleTypeArr();
    this.queryStoreStyleLabelArr();
  },
  components:{
    libStyleDetails
  },
  methods:{
    search(){
      this.styleList=[];
      this.page=1;
      this.total=0;
      this.queryStyleList();
    },
    //查询客户列表
    queryStyleList:function (){
      this.$axios({
        method:"GET",
        url:"/storeStyle/queryList",
        params:{
          storeTypeId:this.form.storeTypeId,
          storeLabelIds:this.form.storeLabelIds.toString(),
          page:this.page,
          limit:30,
        }
      }).then(response=>{

        this.styleList.push(...response.data.data.list);
        console.log(this.styleList)
        this.total=response.data.data.total;
        this.loading = false
        this.disabled = false;
      })
    },
    openStyleImages(storeStyleId){
      this.$axios({
        method: "GET",
        url: "/storeStyleImage/queryLinkListByStyleId",
        params: {
          storeStyleId: storeStyleId
        }
      }).then(response => {
        this.$viewerApi({
          images: response.data.data,
        })
      })
    },
    handleCommand(command) {
      this.$axios({
        method:"GET",
        url:"/storeStyle/queryById",
        params:{
          id:command[1].id
        }
      }).then(response=>{
        this.dialog.styleInfo=response.data.data;
        switch (command[0]){
          case 'edit':
            this.dialog.openEditState = true
            break;
        }
      })
    },
    //查询款式类型库
    queryStoreStyleTypeArr(){
      this.$selectUtils.queryStoreStyleTypeIds().then(response=>{
        this.storeStyleTypeIdArray=JSON.parse(response.data.data)
      })
    },
    //查询款式标签
    queryStoreStyleLabelArr(){
      this.$selectUtils.queryStoreLabelIds().then(response=>{
        this.storeLabelIdArray=JSON.parse(response.data.data)
      })
    },
    //更改每页长度
    handleSizeChange:function (val){
      this.table.limit=val;
      this.queryStyleList();
    },
    handleCurrentChange(val) {
      this.table.page =val
      this.queryStyleList()
    },
    //加载
    load() {
      this.disabled=true;
      if (this.styleList.length>=this.total){
        this.noMore=true;
        this.loading=false;
        return;
      }
      this.loading = true;
      this.noMore=false;
      this.page++;
      setTimeout(() => {
        this.queryStyleList();
      }, 1000)
    },
    close(){
      this.dialog.openEditState=false;
      this.dialog.openImageState=false;
      this.dialog.openStylePrice=false;
    }
  }
}
</script>

<style scoped>
p{
  margin: 5% 0;
  font-size: 23px;
  text-align: center;
}
.pTitle{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  margin: 0 0;
  font-size: 13px;
  font-weight: bold;
}
</style>